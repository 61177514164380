import { Observable } from 'rxjs';
import { JsonContainer } from '../../utils/json-container';
import { StorageUtils } from '../../utils/storage-utils';
import { RestService } from '../../utils/rest-service';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Simulation } from './simulation/model/simulationModel';
import { Email } from './simulation/model/email';
import { id } from '@swimlane/ngx-datatable';

@Injectable({
  providedIn: 'root'
})  
export class SendEmailService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'email', storageUtils);
  }


  email(email: Email, callback: (result: JsonContainer, email: Email) => void) {
    this.executePost('send', email, callback)
  }




}