import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { SendEmailService } from '../sendEmail.service';
import { Email } from '../simulation/model/email';
import { Simulation } from '../simulation/model/simulationModel';
import { UserModel } from '../simulation/model/userModel';
import { User } from 'app/pages/msk-user/user/user';

@Component({
  selector: 'app-root',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  providers: [SendEmailService]
})
export class SendEmailComponent implements OnInit {
  @Input()   
  simulation: Simulation;
  form: FormGroup
  email: Email

  scrollBarHorizontal = (window.innerWidth < 1200);
  @ViewChild('form') validationForm: FormGroup;

  @Input()
  user: User;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private cdRef: ChangeDetectorRef,
    private sendEmailServe: SendEmailService,

  ) {
    this.email = new Email()
  }

  ngOnInit() {
    this.loadData()
  }

  loadData() {
    this.form = new FormGroup({
      title: new FormControl(this.email.title, [Validators.required, Validators.minLength(1)]),
      msg: new FormControl(this.email.msg, [Validators.required, Validators.minLength(1)]),
    }, { updateOn: 'change' })
  }

  send() {
    this.email.user = this.user
    console.log(this.email)
    
    this.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.sendEmailServe.email(this.email, (result, resp) => {
        if (result.success) {
          Swal.fire({
            title: 'Enviado!',
            text: 'E-mail enviado com sucesso.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            this.email.title = ""
            this.email.msg = ""
            this.activeModal.close()
          })
        }
      })
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
