import { CompanyModel } from './companyModel';
import { Odonto } from './insurance/odonto';
import { Life } from './insurance/life';
import { Cyber } from './insurance/cyber';
import { UserModel } from "./userModel";
import { Patrimonial } from './insurance/patrimonial';
import { Guarantee } from './insurance/guarantee';
import { User } from 'app/pages/msk-user/user/user';
import { Company } from 'app/pages/msk-company/company/company';

export class Simulation{
    public id: string;
    public idUser: string;
    public idCompany: string;
    public idUserB: string;
    public idTab: string;
    
    public user: User;
    public company: Company;
    
    public cyber: Cyber;
    public life: Life;
    public odonto: Odonto;
    public patrimonial: Patrimonial;
    public guarantee: Guarantee;

    public status: string;
    public contractedAmount: string;
    public description: string;

    public creationDate: Date;
    
    public pdfUrl: string;
}