import {Injectable} from '@angular/core';

import {UserB} from './user-b';
import {HttpClient} from '@angular/common/http';
import {StorageUtils} from '../../../utils/storage-utils';
import {RestService} from '../../../utils/rest-service';
import {JsonContainer} from '../../../utils/json-container';
import {Role} from '../backoffice-role/role';
import {KeyAuth} from '../../msk-auth-key/key-auth';


@Injectable()
export class UserBService extends RestService {

  constructor(private client: HttpClient, private storageUtils: StorageUtils) {
    super(client, 'userBackoffice', storageUtils);
  }

  authenticate(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('authenticateMobile', userB, callback)
  }

  forgotPassword(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePut('forgotPassword/' + userB.email, null, callback)
  }

  updatePasswordForgot(userB: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('updatePasswordForgot', userB, callback)
  }

  validateKey(keyAuth: KeyAuth, callback: (result: JsonContainer, validated: boolean) => void) {
    this.executePost('validateKey', keyAuth, callback)
  }

  menu(callback: (result: JsonContainer, roles: Role[]) => void) {
    this.executeGet('menu', callback)
  }

  searchAdmin(page: any, callback: (result: JsonContainer, roles: UserB[]) => void) {
    this.executePost('searchAdmin', page, callback)
  }

  changeStatus(user: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('changeStatus', user, callback)
  }

  saveUserB(user: UserB, callback: (result: JsonContainer, user: UserB) => void) {
    this.executePost('saveUser', user, callback)
  }

  loadUserB(id: string, callback: (result: JsonContainer, roles: UserB) => void) {
    this.executeGet('load/' + id, callback)
  }
}
