export class Constants {

  public static readonly DATE_FMT = 'dd/MM/yyyy';
  public static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;

  // Local
  //  public static get SERVICE_URL(): string {
  //   return 'http://localhost:8080';
  //  };


  // Staging
  // public static get SERVICE_URL(): string {
  //     return 'http://api.qualiseg.orangestaging.com';
  // };


  // Production
  public static get SERVICE_URL(): string {
      return  'https://qualiseg.startup-kit.net';   
  };


  public static get SERVICE_PROJETC(): string {
    return '/api/'; 
  };
}
