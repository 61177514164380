<div>
    <div class="modal-header mt-2">
        <h4 class="modal-title" id="modal-basic-title">Enviar mensagem para {{user.name}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="form" novalidate>
        <div class="card-body">
            <div class="mt-2">
                <fieldset class="form-group">
                    <label for="exampleFormControlInput1" class="form-label">Assunto:</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Assunto do email"
                        formControlName="title" [(ngModel)]="email.title">
                    <small class="form-text text-muted danger"
                        *ngIf="!form.get('title').valid && (form.get('title').dirty || form.get('title').touched)">Campo
                        Obrigatório</small>
                </fieldset>
            </div>
            <div class="mt-2">
                <fieldset class="form-group">
                    <label for="exampleFormControlTextarea1" class="form-label">Mensagem:</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="10"
                        formControlName="msg" [(ngModel)]="email.msg"></textarea>
                    <small class="form-text text-muted danger"
                        *ngIf="!form.get('msg').valid && (form.get('msg').dirty || form.get('msg').touched)">Campo
                        Obrigatório</small>
                </fieldset>
            </div>
        </div>
        <div class="row modal-footer justify-content-between pr-3 pl-3">
            <div class="">
                <button class="btn btn-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            </div>
            <div class="">
                <button  [disabled]="!form.valid" (click)="send()" class="btn btn-primary">Confirmar</button>
            </div>
        </div>
    </form>
</div>
