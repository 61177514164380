import {RouteInfo} from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/page', title: 'Page', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '',
    title: 'Menu Levels',
    icon: 'ft-align-left',
    class: 'has-sub',
    badge: '3',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    submenu: [
      {
        path: '/YOUR-ROUTE-PATH',
        title: 'Second Level',
        icon: 'ft-arrow-right submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '',
        title: 'Second Level Child',
        icon: 'ft-arrow-right submenu-icon',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
          {
            path: '/YOUR-ROUTE-PATH',
            title: 'Third Level 1.1',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
          {
            path: '/YOUR-ROUTE-PATH',
            title: 'Third Level 1.2',
            icon: 'ft-arrow-right submenu-icon',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: []
          },
        ]
      },
    ]
  },
];


export const ROUTES_ADMIN: RouteInfo[] = [
  // {
  //   path: '/page/userb/list', title: 'Usuários Backoffice', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/page/simulation/list', title: 'Simulações', icon: 'ft-folder', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page/user/list', title: 'Clientes', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/pages/login', title: 'Sair', icon: 'ft-log-out', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  
  // {
  //   path: '/page/userb/list', title: 'Usuários Backoffice', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  // {
  //   path: '/page/reports', title: 'Análise de Denúncias', icon: 'ft-alert-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // }
];


export const ROUTES_CUSTOMER: RouteInfo[] = [
  // {
  //   path: '/page/dashboard', title: 'Dashboard', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/page/user/list', title: 'Usuários Backoffice', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  
  // {
  //   path: '/page/reports', title: 'Análise de Denúncias', icon: 'ft-alert-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
];
